import { JSX, Show, createEffect, createSignal } from "solid-js";
import { useParams } from "@solidjs/router";

import { css } from "#style/css";
import { Box, VStack, HStack } from "#style/jsx";

import { useTranslation } from "#root/domain/i18n";
import {
  useAccountWideSessionsUpdates,
  useSessionUpdates,
} from "#root/domain/session";
import { Session, Question } from "#root/module/api";
import { Typography } from "#root/components/Typography";
import { Logo } from "#root/components/Logo";

type RouteParam = {
  userId: string;
};

export default function OverlayByName() {
  const { t } = useTranslation();
  const params = useParams<RouteParam>();
  const accountWideSessionsUpdates = useAccountWideSessionsUpdates(
    params.userId,
  );
  const sessionUpdates = useSessionUpdates(() => activeSession()?.id, {
    needAuth: false,
  });

  const [activeSession, setActiveSession] = createSignal<Session | null>(null);
  const [question, setQuestion] = createSignal<Question | null>();

  const baseTranslationKey = () => {
    const isAIPowered = Boolean(activeSession()?.aiEnabled);
    return isAIPowered ? "overlay.placeholderWithAi" : "overlay.placeholder";
  };

  createEffect(() => {
    if (!accountWideSessionsUpdates.isConnected()) return;

    accountWideSessionsUpdates.pushEvent(
      "get:session:active",
      {},
      (response) => {
        switch (response.status) {
          case "empty":
            setActiveSession(null);
            break;
          case "success":
            setActiveSession(response.data);
            break;
          default:
            break;
        }
      },
    );

    accountWideSessionsUpdates.addEventListener(
      "session:created",
      ({ data }) => {
        setActiveSession(data);
      },
    );

    accountWideSessionsUpdates.addEventListener("session:terminated", ({}) => {
      setActiveSession(null);
      setQuestion(null);
    });
  });

  createEffect(() => {
    if (!sessionUpdates.isConnected()) return;

    sessionUpdates.addEventListener("question:focused", (msg) => {
      switch (msg.status) {
        case "empty":
          setQuestion(null);
          break;

        case "success":
          setQuestion(msg.data);
          break;
      }
    });

    sessionUpdates.pushEvent("get:question:focused", {}, (msg) => {
      switch (msg.status) {
        case "empty":
          setQuestion(null);
          break;

        case "success":
          setQuestion(msg.data);
          break;
      }
    });
  });

  return (
    <Show when={activeSession()}>
      <Show
        when={question()}
        fallback={
          <OverlayWrapper
            title={<Logo />}
            text={
              <span class={css({ textAlign: "center" })}>
                {t(`${baseTranslationKey()}.0`)}
                <span
                  class={css({
                    fontWeight: "bold",
                    backgroundColor: "white",
                    px: "2",
                    borderRadius: "sm",
                  })}
                >
                  {t(`${baseTranslationKey()}.1`)}
                </span>{" "}
                {t(`${baseTranslationKey()}.2`)}
              </span>
            }
          />
        }
      >
        {(result) => (
          <OverlayWrapper
            title={
              <span>
                {t("overlay.questionFrom", {
                  username: result().twitchUsername,
                })}
              </span>
            }
            text={result().question}
          />
        )}
      </Show>
    </Show>
  );
}

type OverlayWrapperProps = {
  title: JSX.Element;
  text: JSX.Element;
};
function OverlayWrapper(props: OverlayWrapperProps) {
  return (
    <Box position="fixed" bottom="0" left="0" w="full" p="10" pl="20">
      <VStack
        w="fit-content"
        h="fit-content"
        alignItems="center"
        background="yellow"
        transform="translateY(50%) translateX(-40px)"
        py="3"
        px="4"
        borderRadius="md"
        border="thick"
        borderStyle="solid"
        borderColor="black"
      >
        <Typography class={css({ fontSize: "3xl" })}>{props.title}</Typography>
      </VStack>

      <HStack
        bg="yellow"
        justifyContent="flex-start"
        alignItems="center"
        borderRadius="md"
        py="12"
        px="12"
        boxShadow="12px 12px 0px 0px var(--colors-black)"
        border="thick"
        borderStyle="solid"
        borderColor="black"
        w="full"
      >
        <Typography
          textStyle="bodyImportant"
          class={css({
            fontSize: "4xl",
            w: "fit-content",
            flex: "1",
          })}
        >
          {props.text}
        </Typography>
      </HStack>
    </Box>
  );
}
